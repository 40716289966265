import React, { Component } from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../layout";
import UserInfo from "../components/UserInfo";
import PostTags from "../components/PostTags";
import config from "../../data/SiteConfig";
import { formatDate, editOnGithub } from "../utils/global";
import aaron from "../../content/images/profile.jpg";
import { MDXRenderer } from "gatsby-plugin-mdx";

function waitForGlobal(name, timeout = 300) {
  return new Promise((resolve, reject) => {
    let waited = 0;

    function wait(interval) {
      setTimeout(() => {
        waited += interval;
        // some logic to check if script is loaded
        // usually it something global in window object
        if (window[name] !== undefined) {
          return resolve();
        }
        if (waited >= timeout * 1000) {
          return reject({ message: "Timeout" });
        }
        wait(interval * 2);
      }, interval);
    }

    wait(30);
  });
}

export default class PostTemplate extends Component {
  componentDidMount() {
    waitForGlobal("MathJax").then(() => {
      top.MathJax.Hub.Config({
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["[", "]"],
          ],
          processEscapes: true,
          processEnvironments: true,
          skipTags: ["script", "noscript", "style", "textarea", "pre"],
          TeX: {
            equationNumbers: { autoNumber: "AMS" },
            extensions: ["AMSmath.js", "AMSsymbols.js"],
          },
        },
      });
    });
    if (top.MathJax != null) {
      top.MathJax.Hub.Queue(["Typeset", top.MathJax.Hub]);
    }
  }
  componentDidUpdate() {
    if (top.MathJax != null) {
      top.MathJax.Hub.Queue(["Typeset", top.MathJax.Hub]);
    }
  }
  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.mdx;
    const post = postNode.frontmatter;
    let thumbnail;

    if (!post.id) {
      post.id = slug;
    }

    if (!post.category_id) {
      post.category_id = config.postDefaultCategoryID;
    }

    if (post.thumbnail) {
      thumbnail = post.thumbnail.childImageSharp.gatsbyImageData;
    }

    const date = formatDate(post.date);

    const IMAGE_KEY = "image";
    const embeddedImagesByKey =
      post.images &&
      post.images.reduce((imgs, image, index) => {
        imgs[`${IMAGE_KEY}${index + 1}`] = imgs[`${IMAGE_KEY}${index + 1}`] || {
          ...image,
        };
        return imgs;
      }, {});

    return (
      <Layout>
        <Helmet>
          <title>{`${post.title} – ${config.siteTitle}`}</title>
          <script
            type="text/javascript"
            src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-AMS-MML_HTMLorMML"
            async
          />
        </Helmet>
        <article className="single container">
          <header
            className={`single-header ${!thumbnail ? "no-thumbnail" : ""}`}
          >
            {thumbnail && (
              <GatsbyImage
                image={post.thumbnail.childImageSharp.gatsbyImageData}
                style={{ borderRadius: 10 }}
                className="thumbnail"
                alt="thumbnail"
              />
            )}
            <div className="flex">
              <h1>{post.title}</h1>
              <time className="date">{post.description}</time>
              <div className="post-meta">
                {/* <Link to="/me">
                  <img src={aaron} className="avatar-small" alt="Aaron" />
                </Link> */}
                <time className="date">{date}</time>
                {/* <a
                  className="twitter-link"
                  href={twitterShare}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Share
                </a> */}
              </div>
              <PostTags tags={post.tags} />
            </div>
          </header>

          <MDXRenderer className="post" images={embeddedImagesByKey}>
            {postNode.body}
          </MDXRenderer>
        </article>
        {/* <UserInfo config={config} /> */}
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      excerpt
      frontmatter {
        title
        thumbnail {
          childImageSharp {
            gatsbyImageData(width: 150, height: 150, layout: FIXED)
          }
        }
        images {
          childImageSharp {
            original {
              width
              height
              src
            }
            gatsbyImageData(quality: 90, layout: FULL_WIDTH)
          }
          publicURL
          extension
        }
        slug
        date
        categories
        tags
        description
        template
      }
      fields {
        slug
        date
      }
    }
  }
`;
