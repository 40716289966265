import React, { Component } from "react";
import GitHubButton from "react-github-btn";

export default class ProjectListing extends Component {
  render() {
    const { projects } = this.props;

    return (
      <section className="projects">
        {projects.map((project) => (
          <a
            className="project-link"
            href={project.path}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="each" key={project.title}>
              <h2>
                <div className="project-icon">{project.icon}</div>
                <div className="project-title">{project.title}</div>
              </h2>
              <p>{project.description}</p>
              <div className="buttons">
                {project.source && (
                  <GitHubButton
                    href={project.source}
                    data-size="large"
                    // data-show-count="true"
                  >
                    Source
                  </GitHubButton>
                )}

                {/* {project.path && (
                  <a
                    className="button"
                    href={project.path}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn More
                  </a>
                )} */}
              </div>
            </div>
          </a>
        ))}
      </section>
    );
  }
}
