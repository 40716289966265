import React from "react";
import seng from "../content/experience-icons/seng.svg";
import sbm from "../content/experience-icons/sbm.svg";
import ust from "../content/experience-icons/ust.svg";
import upenn from "../content/experience-icons/upenn.svg";
import vsa from "../content/experience-icons/vsa.svg";

const ustcourses = {
  "Fall 2017": [
    { code: "COMP1022P", name: "Introduction to Computing: Java" },
    { code: "COMP2711", name: "Discrete Mathematics for Computer Science" },
    { code: "MATH2111", name: "Matrix Algebra and Applications" },
    { code: "LANG2030H", name: "Technical Communication 1" },
    { code: "LANG1113I", name: "Effective Chinese Communication" },
  ],
  "Spring 2018": [
    { code: "MATH2023", name: "Multivariable Calculus" },
    { code: "MATH2411", name: "Applied Statistics" },
    { code: "FINA2203", name: "Fundamentals of Business Finance" },
    { code: "HUMA1000C", name: "Cultures and Values" },
    {
      code: "ENGG2900D",
      name: "Underwater Robot Community Engagement Project",
    },
    { code: "ENGG3960A", name: "ABU Robocon 2018" },
  ],
  "Fall 2018": [
    {
      code: "COMP2012H",
      name: "Honors Object-Oriented Programming and Data Structures",
    },
    { code: "COMP3711H", name: "Honors Design and Analysis of Algorithms" },
    { code: "COMP2611", name: "Computer Organization" },
    { code: "MATH3332", name: "Data Analytics Tools" },
    { code: "MGMT2110", name: "Organizational Behavior" },
    { code: "ACCT2010", name: "Principles of Accounting I" },
    { code: "PHYS1003", name: "Energy and Related Environmental Issues" },
  ],
  "Spring 2019": [
    { code: "COMP5712", name: "Discrete Mathematics for Computer Science" },
    { code: "COMP4971C", name: "Independent Work" },
    { code: "MATH2033", name: "Mathematical Analysis" },
    { code: "MATH3322", name: "Matrix Computation" },
    { code: "ELEC3300", name: "Embedded Systems" },
    { code: "LABU2060", name: "Effective Business Communication" },
    { code: "MARK2120", name: "Marketing Management" },
    { code: "ENGG3960D", name: "ABU Robocon 2019" },
  ],
  "Fall 2019": [
    { code: "COMP5711", name: "Advanced Algorithms" },
    { code: "COMP5331", name: "Knowledge Discovery in Databases" },
    {
      code: "MATH5471",
      name: "Statistical Learning Models for Text and Graph Data",
    },
    { code: "COMP3111H", name: "Honors Software Engineering" },
    { code: "ISOM2700", name: "Operatins Management" },
    { code: "ECON2103", name: "Principles of Microeconomics" },
    { code: "TEMG3950", name: "Case-Based Problem Solving" },
    { code: "TEMG4950D", name: "Smarter e-Commerce for Google HK" },
  ],
};

export default [
  {
    icon: <img src={ust} style={{ backgroundColor: "white", scale: 0.9 }} />,
    title: [
      "BEng in Computer Science, in Mathematics, and in Data Science",
      "BBA in General Business Management",
      "Minors in Robotics and Psychology",
    ],
    place: "The Hong Kong University of Science and Technology",
    start: "September 2017",
    end: "June 2021",
    current: true,
    link: "/hkust/",
    // gpa: "3.985 / 4.3",
  },
  // {
  //   icon: (
  //     <img src={sbm} style={{ backgroundColor: "#1C3A74", fill: "white" }} />
  //   ),
  //   title: "BBA in Business Management",
  //   place: "HKUST Business School",
  //   start: "September 2017",
  //   end: "June 2021",
  //   current: true,
  //   gpa: "3.95 / 4.3",
  // },
  // {
  //   icon: <img src={upenn} style={{ backgroundColor: "white" }} />,
  //   title: "Semester Exchange Student",
  //   place: "University of Pennsylvania",
  //   start: "January 2020",
  //   end: "May 2020",
  //   link: "/upenn/",
  //   // gpa: "3.94 / 4.0",
  // },
  // {
  //   icon: <img src={vsa} style={{ backgroundColor: "white" }} />,
  //   title: "International Baccalaurate Program",
  //   place: "Victoria Shanghai Academy",
  //   start: "September 2015",
  //   end: "May 2017",
  //   current: false,
  //   link: "/vsa/",
  //   // gpa: "44 / 45 (Valedictorian)",
  // },
];
