import React, { Component } from "react";
import tania from "../../content/images/profile.jpg";
import github from "../../content/images/github.png";

export default class UserInfo extends Component {
  render() {
    return (
      <aside className="note">
        <div className="container note-container">
          <div className="flex-author">
            <div className="flex-avatar">
              <img className="avatar" src={tania} alt="Tania Rascia" />
            </div>
            <div>
              <p>
                I'm Aaron Wang. I document everything I learn and help thousands
                of people start coding careers.{" "}
                <strong>
                  My site has no ads, sponsors, or bullshit. If you enjoy my
                  content, please consider supporting what I do.
                </strong>
              </p>

              <div className="flex">
                <a
                  href="https://www.github.com/wangaaron78739"
                  className="donate-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={github} className="coffee-icon" alt="Coffee icon" />
                  Check out my Github
                </a>
                <a
                  className="patreon-button"
                  href="https://www.patreon.com/taniarascia"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {/* <img src={patreon} height="50" width="50" alt="Patreon" />{" "} */}
                  Become a Patron
                </a>
              </div>
            </div>
          </div>
        </div>
      </aside>
    );
  }
}
