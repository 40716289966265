import React, { Component, useState } from "react";

import { Link } from "gatsby";

export default class ExperienceListing extends Component {
  render() {
    const { data } = this.props;
    return (
      <section className="experience-list">
        {data.map((exp) => {
          return (
            <Link
              to={exp.link}
              key={exp.degree}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="each">
                {exp.icon}
                <div className="col">
                  {Array.isArray(exp.title) ? (
                    exp.title.map((title) => <h2>{title}</h2>)
                  ) : (
                    <h2>{exp.title}</h2>
                  )}
                  <h3>{exp.place}</h3>
                  {/* {exp.current ? <h3>{exp.start} - Expected({exp.end})</h3> : <h3>{exp.start} - {exp.end}</h3>} */}
                  <h3>
                    {exp.start} - {exp.current ? "Present" : exp.end}
                  </h3>
                  {exp.gpa && <h3> GPA: {exp.gpa}</h3>}
                </div>
              </div>
            </Link>
          );
        })}
      </section>
    );
  }
}
