import React, { Component } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import { MDXRenderer } from "gatsby-plugin-mdx";

export default class PageTemplate extends Component {
  render() {
    const { slug } = this.props.pageContext;
    const postNode = this.props.data.mdx;
    const page = postNode.frontmatter;

    if (!page.id) {
      page.id = slug;
    }

    const IMAGE_KEY = "image";
    const embeddedImagesByKey =
      page.images &&
      page.images.reduce((imgs, image, index) => {
        imgs[`${IMAGE_KEY}${index + 1}`] = imgs[`${IMAGE_KEY}${index + 1}`] || {
          ...image,
        };
        return imgs;
      }, {});

    return (
      <Layout>
        <Helmet>
          <title>{`${page.title} – ${config.siteTitle}`}</title>
        </Helmet>
        <div className="container">
          <article>
            <header className="page-header">
              <h1>{page.title}</h1>
            </header>
            <MDXRenderer className="page" images={embeddedImagesByKey}>
              {postNode.body}
            </MDXRenderer>
          </article>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`query PageBySlug($slug: String!) {
  mdx(fields: {slug: {eq: $slug}}) {
    body
    timeToRead
    excerpt
    frontmatter {
      title
      template
      images {
        childImageSharp {
          original {
            width
            height
            src
          }
          gatsbyImageData(quality: 90, layout: FULL_WIDTH)
        }
        publicURL
        extension
      }
    }
    fields {
      slug
      date
    }
  }
}
`;
