import React, { Component } from "react";
import Helmet from "react-helmet";
import GitHubButton from "react-github-btn";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing";
import ProjectListing from "../components/ProjectListing";
import SimpleListing from "../components/SimpleListing";
import config from "../../data/SiteConfig";
import education from "../../data/education";
import work from "../../data/work";
import speaking from "../../data/speaking";
import podcasts from "../../data/podcasts";
import quotes from "../../data/quotes";
import aaron from "../../content/images/profile.jpg";
import ExperienceListing from "../components/ExperienceListing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Index extends Component {
  render() {
    const { data } = this.props;

    const latestPostEdges = data.latest.edges;
    const popularPostEdges = data.popular.edges;
    const selectedPostEdges = data.selected.edges;

    return (
      <Layout>
        <Helmet title={`${config.siteTitle}`} />
        <div className="container">
          <div className="lead">
            <div className="elevator">
              <h1>{`Hey, I'm Aaron 👋🏻`}</h1>
              <p>
                I'm a software engineer currently based in NYC. This website is
                mostly out of date but hopefully I'll get around to updating
                this site soon. Stay tuned!
              </p>
            </div>
            <div className="profile-section">
              <img src={aaron} className="profile-avatar" alt="Aaron" />
              {/* <d>
                <FontAwesomeIcon icon={["fab", "github"]} href="/contact" />
                <a href=""></a>
              </d> */}
            </div>
          </div>
        </div>

        <div className="container front-page">
          {/*
          <section className="section">
            <h2>
              Projects
              <Link to="/projects" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={selectedPostEdges} />
          </section>
          */}
          <section className="section">
            <h2>Education</h2>
            <ExperienceListing simple data={education} />
          </section>
          {/* <section className="section">
            <h2>
              Most Popular
              <Link to="/categories/popular" className="view-all">
                View all
              </Link>
            </h2>
            <PostListing simple postEdges={popularPostEdges} />
          </section> */}
          {/* 
          <section className="section">
            <h2>Projects</h2>
            <ProjectListing projects={projects} />
          </section>

          <section className="section">
            <h2>Talks</h2>
            <SimpleListing simple data={speaking} />
          </section> */}
          {/* 
          <section className="section">
            <h2>{`Other People's Opinions`}</h2>
            <div className="quotations">
              {quotes.map(quote => (
                <blockquote className="quotation" key={quote.name}>
                  <p>{quote.quote}</p>
                  <cite>— {quote.name}</cite>
                </blockquote>
              ))}
            </div>
          </section> */}
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMdx(
      limit: 6
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { template: { eq: "post" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 150, height: 150, layout: FIXED)
              }
            }
            date
            template
            description
          }
        }
      }
    }
    popular: allMdx(
      limit: 9
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Popular" } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 150, height: 150, layout: FIXED)
              }
            }
            date
            template
          }
        }
      }
    }
    selected: allMdx(
      limit: 9
      sort: { fields: [frontmatter___selected], order: ASC }
      filter: { frontmatter: { selected: { gt: 0 } } }
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            categories
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 150, height: 150, layout: FIXED)
              }
            }
            date
            template
            description
          }
        }
      }
    }
  }
`;
